import React from "react"
import Layout from "../components/layout"

const IndexPage = () => (
	<Layout>
		<h1>Helping communities prepare for and respond to emergencies.</h1>
		<div className="button-group">
			<a href="/schedule-demo/" target="_self" className="btn primary">Schedule demo</a>
			<a href="https://app.alertplan.co" target="_self" className="btn secondary">Sign in</a>
		</div>
  </Layout>
)

export default IndexPage
